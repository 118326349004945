<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area" style="padding-top: 60px; padding-bottom: 0px;">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>Contact Us</h2>
                    </div>
                </div>
			</div>

			<div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape5"><img src="../../../assets/img/shape5.png" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        </div>
        <!-- End Page Title -->

        <!-- Start Contact Info Area -->
		<section class="contact-info-area ptb-80" style="padding-bottom: 180px;">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="contact-info-box">
                            <div class="icon">
                                <feather type="mail"></feather>
                            </div>
                            <h3>Email</h3>
                            <br>
                            <p><a href="#">contact@thedeft.io</a></p>
                            <br>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="contact-info-box">
                            <div class="icon">
                                <feather type="map-pin"></feather>
                            </div>
                            <h3>ADDRESS</h3>
                            <p>78 Soi Khubon 6, Ram Inthra rd. Khwaend Ram Inthra, Khet Khan na Yao, Bangkok 10230, Thailand.</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div class="contact-info-box">
                            <div class="icon">
                                <feather type="phone"></feather>
                            </div>
                            <h3>PHONES</h3>
                            <br>
                            <p><a href="#">02-9430174</a></p>
                            <p><a href="#">082-989-9160</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Contact Info Area -->

    </div>
</template>

<script>
export default {
    name: 'Contact'
}
</script>