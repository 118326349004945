<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>Blog Grid</h2>
                    </div>
                </div>
			</div>
			
			<div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape5"><img src="../../../assets/img/shape5.png" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        </div>
        <!-- End Page Title -->

        <!-- Start Blog Area -->
		<section class="blog-area ptb-80">
			<div class="container">
				<div class="row">
					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="#">
									<img src="../../../assets/img/blog-image/1.jpg" alt="image">
								</a>

								<div class="date">
									<feather type="calendar"></feather> March 15, 2019
								</div>
							</div>

							<div class="blog-post-content">
								<h3><a href="#">The security risks of changing package owners</a></h3>

								<span>by <a href="#">admin</a></span>

								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

								<a href="#" class="read-more-btn">Read More <feather type="arrow-right"></feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="#">
									<img src="../../../assets/img/blog-image/2.jpg" alt="image">
								</a>

								<div class="date">
									<feather type="calendar"></feather> March 17, 2019
								</div>
							</div>

							<div class="blog-post-content">
								<h3><a href="#">Tips to Protecting Your Business and Family</a></h3>

								<span>by <a href="#">smith</a></span>

								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

								<a href="#" class="read-more-btn">Read More <feather type="arrow-right"></feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="#">
									<img src="../../../assets/img/blog-image/3.jpg" alt="image">
								</a>

								<div class="date">
									<feather type="calendar"></feather> March 19, 2019
								</div>
							</div>

							<div class="blog-post-content">
								<h3><a href="#">Protect Your Workplace from Cyber Attacks</a></h3>

								<span>by <a href="#">john</a></span>

								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

								<a href="#" class="read-more-btn">Read More <feather type="arrow-right"></feather> </a>
							</div>
						</div>
					</div>
					
					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="#">
									<img src="../../../assets/img/blog-image/4.jpg" alt="image">
								</a>

								<div class="date">
									<feather type="calendar"></feather> March 15, 2019
								</div>
							</div>

							<div class="blog-post-content">
								<h3><a href="#">The security risks of changing package owners</a></h3>

								<span>by <a href="#">admin</a></span>

								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

								<a href="#" class="read-more-btn">Read More <feather type="arrow-right"></feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="#">
									<img src="../../../assets/img/blog-image/5.jpg" alt="image">
								</a>

								<div class="date">
									<feather type="calendar"></feather> March 17, 2019
								</div>
							</div>

							<div class="blog-post-content">
								<h3><a href="#">Tips to Protecting Your Business and Family</a></h3>

								<span>by <a href="#">smith</a></span>

								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

								<a href="#" class="read-more-btn">Read More <feather type="arrow-right"></feather> </a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-blog-post">
							<div class="blog-image">
								<a href="#">
									<img src="../../../assets/img/blog-image/6.jpg" alt="image">
								</a>

								<div class="date">
									<feather type="calendar"></feather> March 19, 2019
								</div>
							</div>

							<div class="blog-post-content">
								<h3><a href="#">Protect Your Workplace from Cyber Attacks</a></h3>

								<span>by <a href="#">john</a></span>

								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

								<a href="#" class="read-more-btn">Read More <feather type="arrow-right"></feather> </a>
							</div>
						</div>
					</div>
					
					<div class="col-lg-12 col-md-12">
						<div class="pagination-area">
							<nav aria-label="Page navigation">
								<ul class="pagination justify-content-center">
									<li class="page-item"><a class="page-link" href="#">Prev</a></li>
									<li class="page-item active"><a class="page-link" href="#">1</a></li>
									<li class="page-item"><a class="page-link" href="#">2</a></li>
									<li class="page-item"><a class="page-link" href="#">3</a></li>
									<li class="page-item"><a class="page-link" href="#">Next</a></li>
								</ul>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- End Blog Area -->
    </div>
</template>
<script>
export default {
    name: 'BlogGrid'
}
</script>