<template>
    <!-- Start Cloud Hosting Services -->
    <section class="services-area ptb-80 bg-f7fafd">
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 services-content">
                    <div class="section-title">
                        <h2>Cloud Hosting Services</h2>
                        <div class="bar"></div>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="database"></feather> Cloud databases
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="globe"></feather> Website hosting
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="file"></feather> File storage
                            </div>
                        </div>

                        <!-- <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="trending-up"></feather> Forex trading
                            </div>
                        </div> -->

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="folder"></feather> File backups
                            </div>
                        </div>

                        <!-- <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="monitor"></feather> Remote desktop
                            </div>
                        </div> -->

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="mail"></feather> Email servers
                            </div>
                        </div>

                        <!-- <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="cloud"></feather> Hybrid cloud
                            </div>
                        </div> -->
                    </div>
                </div>

                <div class="col-lg-6 col-md-12 services-right-image">
                    <img src="../../../assets/img/services-right-image/book-self.png" class="wow fadeInDown" v-wow data-wow-delay="0.6s" alt="book-self">
                    <img src="../../../assets/img/services-right-image/box.png" class="wow fadeInUp" v-wow data-wow-delay="0.6s" alt="box">
                    <img src="../../../assets/img/services-right-image/chair.png" class="wow fadeInLeft" v-wow data-wow-delay="0.6s" alt="chair">
                    <img src="../../../assets/img/services-right-image/cloud.png" class="wow zoomIn" v-wow data-wow-delay="0.6s" alt="cloud">
                    <img src="../../../assets/img/services-right-image/cup.png" class="wow bounceIn" v-wow data-wow-delay="0.6s" alt="cup">
                    <img src="../../../assets/img/services-right-image/flower-top.png" class="wow fadeInDown" v-wow data-wow-delay="0.6s" alt="flower">
                    <img src="../../../assets/img/services-right-image/head-phone.png" class="wow zoomIn" v-wow data-wow-delay="0.6s" alt="head-phone">
                    <img src="../../../assets/img/services-right-image/monitor.png" class="wow fadeInUp" v-wow data-wow-delay="0.6s" alt="monitor">
                    <img src="../../../assets/img/services-right-image/mug.png" class="wow rotateIn" v-wow data-wow-delay="0.6s" alt="mug">
                    <img src="../../../assets/img/services-right-image/table.png" class="wow fadeInUp" v-wow data-wow-delay="0.6s" alt="table">
                    <img src="../../../assets/img/services-right-image/tissue.png" class="wow zoomIn" v-wow data-wow-delay="0.6s" alt="tissue">
                    <img src="../../../assets/img/services-right-image/water-bottle.png" class="wow zoomIn" v-wow data-wow-delay="0.6s" alt="water-bottle">
                    <img src="../../../assets/img/services-right-image/wifi.png" class="wow fadeInLeft" v-wow data-wow-delay="0.6s" alt="wifi">
                    <img src="../../../assets/img/services-right-image/cercle-shape.png" class="bg-image rotateme" alt="shape">

                    <img src="../../../assets/img/services-right-image/main-pic.png" class="wow fadeInUp" v-wow data-wow-delay="0.6s" alt="main-pic">
                </div>
            </div>
        </div>
    </section>
    <!-- End Cloud Hosting Services -->
</template>

<script>
export default {
    name: 'Services'
}
</script>