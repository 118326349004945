<template>
  <div>
    <HeaderTwo v-if="currentUrl == '/web-hosting'"></HeaderTwo>
    <HeaderThree v-else-if="currentUrl == '/machine-learning'"></HeaderThree>
    <HeaderFour v-else-if="currentUrl == '/digital-agency'"></HeaderFour>
    <HeaderFour v-else-if="currentUrl == '/digital-agency'"></HeaderFour>
    <div v-else-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <Header v-else></Header>
    <PreLoader v-if="isLoading" />
    <router-view></router-view>
    <div v-if="currentUrl == '/not-found' || currentUrl == '/coming-soon'"></div>
    <Footer v-else></Footer>
  </div>
</template>

<script>
import Header from './components/layout/Header';
import HeaderTwo from './components/layout/HeaderTwo';
import HeaderThree from './components/layout/HeaderThree';
import HeaderFour from './components/layout/HeaderFour';
import Footer from './components/layout/Footer';
import PreLoader from './components/layout/PreLoader';
export default {
  name: 'app',
  metaInfo() {
    return {
        title: "THE DEFT TECHNOLOGY",
        meta: [
          {
            hid: 'og:title',
            name: 'og:title',
            content: 'THE DEFT - We believe the quality of the software is important.'
          },
          {
            hid: 'description',
            name: 'description',
            content: 'We believe the quality of the software is important.'
          },
          {
            hid: 'og:site_name',
            name: 'og:site_name',
            content: 'THE DEFT TECHNOLOGY.'
          },
          {
            hid: 'og:type',
            name: 'og:type',
            content: 'website'
          },
          {
            hid: 'og:image',
            name: 'og:image',
            content: 'https://thedeft.io/logo.png'
          },
          {
            hid: 'robots',
            name: 'robots',
            content: 'index,follow'
          }
        ]
    }
  },
  components: {
    Header, HeaderTwo, HeaderThree, HeaderFour, Footer, PreLoader
  },
  data() {
    return {
      isLoading: true,
      currentUrl: ''
    }
  },

  watch: {
    '$route'(pathUrl){
      this.currentUrl = pathUrl.path;
      this.isLoading = true;
      setTimeout(() => { this.isLoading = false }, 1500);
    }
  },

  mounted() {
    this.currentUrl = window.location.pathname;
    setTimeout(() => {
      this.isLoading = false
    }, 2000);
  }
}
</script>
