<template>
    <!-- Start Boxes Area -->
    <section class="boxes-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-box">
                        <div class="icon">
                            <feather type="server"></feather>
                        </div>
                        <h3>Cloud Server</h3>
                        <!-- <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna.</p> -->
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-box bg-f78acb">
                        <div class="icon">
                            <feather type="code"></feather>
                        </div>
                        <h3>Software Development</h3>
                        <!-- <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna.</p> -->
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-box bg-c679e3">
                        <div class="icon">
                            <feather type="users"></feather>
                        </div>
                        <h3>UX/UI</h3>
                        <!-- <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna.</p> -->
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-box bg-eb6b3d">
                        <div class="icon">
                            <feather type="git-branch"></feather>
                        </div>
                        <h3>Version Control</h3>
                        <!-- <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna.</p> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Boxes Area -->
</template>

<script>
export default {
    name: 'BoxesArea'
}
</script>