<template>
    <!-- Start Works Area -->
    <section class="works-area ptb-80 bg-f7fafd">
        <div class="container">
            <div class="section-title">
                <h2>Our Recent Works</h2>
                <div class="bar"></div>
                <p>A Few Of Our Recent Projects.</p>
            </div>
        </div>

        <div class="works-slides">
            <carousel
                :autoplay = "true"
                :paginationEnabled = "false"
                :loop = "true"
                :autoplaySpeed = "true"
                :autoplayTimeout = "5000"
                :perPageCustom = "[[0, 1],[768, 2],[1200, 3], [1500, 4]]"
            >

                <slide>
                    <div class="single-works">
                        <img src="../../../assets/img/works-image/4.jpg" alt="image">

                        <a href="#" class="icon"><feather type="settings"></feather></a>

                        <div class="works-content">
                            <h3><a href="#">Application Last Mile</a></h3>
                            <p>Application for Carrier.</p>
                        </div>
                    </div>
                </slide>

                <slide>
                    <div class="single-works">
                        <img src="../../../assets/img/works-image/1.jpg" alt="image">

                        <a href="#" class="icon"><feather type="settings"></feather></a>

                        <div class="works-content">
                            <h3><a href="#">Last Mile Management System</a></h3>
                            <p>Delivery Management Software.</p>
                        </div>
                    </div>
                </slide>

                <slide>
                    <div class="single-works">
                        <img src="../../../assets/img/works-image/3.jpg" alt="image">

                        <a href="#" class="icon"><feather type="settings"></feather></a>

                        <div class="works-content">
                            <h3><a href="#">UI and UX Design</a></h3>
                            <p>Development Website by Requirements.</p>
                        </div>
                    </div>
                </slide>

                <slide>
                    <div class="single-works">
                        <img src="../../../assets/img/works-image/2.jpg" alt="image">

                        <a href="#" class="icon"><feather type="settings"></feather></a>

                        <div class="works-content">
                            <h3><a href="#">Application Scan Barcode</a></h3>
                            <p>Mobile Application Scan Barcode for Customs clearance process.</p>
                        </div>
                    </div>
                </slide>


                <slide>
                    <div class="single-works">
                        <img src="../../../assets/img/works-image/5.jpg" alt="image">

                        <a href="#" class="icon"><feather type="settings"></feather></a>

                        <div class="works-content">
                            <h3><a href="#">Customer Relationship Management System</a></h3>
                            <p>Customer Relationship Management System.</p>
                        </div>
                    </div>
                </slide>


                <slide>
                    <div class="single-works">
                        <img src="../../../assets/img/works-image/6.jpg" alt="image">

                        <a href="#" class="icon"><feather type="settings"></feather></a>

                        <div class="works-content">
                            <h3><a href="#">Thailand Research Expo 2020</a></h3>
                            <p>Webiste Registration Exhibition and Conference.</p>
                        </div>
                    </div>
                </slide>
            </carousel>
        </div>

        <div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        <div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        <div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
    </section>
    <!-- End Works Area -->
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
    name: 'Works',
    components: { Carousel, Slide }
}
</script>